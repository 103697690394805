import React, { FC } from "react"
import { Stack, Center, Box, Switcher, Frame, Text, Cluster } from "../common"
import { css } from "@emotion/core"
import { Layout } from "../components/Layout"
import { ZHelmet } from "../components/ZHelmet"

const DATA_POINTS = [
  {
    heading: "Working from",
    content: "36+ months",
    background:
      "https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_400/v1597802642/data_background_pzyj6l.png",
  },
  {
    heading: "Loans disbursed till date",
    content: "250+ cr",
    background:
      "https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_400/v1597802832/Group_187_ntkxjf.png",
  },
  {
    heading: "Active Customers",
    content: "150k+",
    background:
      "https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_400/v1597802832/Group_188_pvbisx.png",
  },
]

export default function AboutUs(props) {
  return (
    <Layout>
      <ZHelmet
        title="Preferred personal loan provider | Zavron Finserv | NBFC | India"
        description="Learn more about Zavron Finserv which focus on to finish all your financial requirements by providing you the instant personal loan with less documentation"
      />
      <Stack space="var(--s5)">
        <Landing />
        {/* <DataPoints /> */}
        <OurVision />
      </Stack>
    </Layout>
  )
}

const Landing: FC = props => {
  return (
    <Box>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
          <Box>
            <Frame numerator={29} denominator={20}>
              <img
                src="https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,f_auto,w_700/v1595769253/ft1zo1gv3brfwmreooav.png"
                alt="aboutUs"
              />
            </Frame>
          </Box>
          <Stack style={{ flexGrow: 2 }} space="var(--s2)" justify="center">
            <Stack space="var(--s1)" justify="center">
              <Text variant="h2">About Us</Text>
              <Text
                variant="h4"
                style={{
                  fontFamily: "var(--body-font-family)",
                  lineHeight: "150%",
                }}
                color="light"
              >
                Zavron Finserv is a Non-Banking Finance Company that offers
                small loans at competitive interest rates instantly. We are your
                cash partners in times of emergency or any personal needs. You
                can rely on us if you are planning to expand your business, buy
                a two-wheeler or even a used car. Our customer friendly terms
                are meant for you to choose the best loan product for yourself.
              </Text>
              {/* <Text
                variant="h4"
                style={{
                  fontFamily: "var(--body-font-family)",
                  lineHeight: "150%",
                }}
                color="light"
              >
                Our digital loan services will help you apply for a loan in just
                a few minutes. We ensure our experienced leadership and talented
                team delivers seamless and quick loan experience to you. You can
                download our exclusive app ZCash and get a loan in just a few
                taps.
              </Text> */}
            </Stack>
          </Stack>
        </Switcher>
      </Center>
    </Box>
  )
}

// const DataPoints: FC = props => {
//   return (
//     <Box>
//       <Center gutters="var(--s2)" maxWidth="var(--max-width)">
//         <Switcher threshold="var(--min-width)" space="var(--s2)" max={3}>
//           {DATA_POINTS.map(item => (
//             <Box
//               style={{ borderRadius: 5 }}
//               padding="var(--s3)"
//               css={css`
//                 background-image: url("${item.background}");
//                 background-size: cover;
//               `}
//             >
//               <Stack space="var(--s-1)">
//                 <Text
//                   style={{
//                     color: "rgba(255, 255, 255, 0.6)",
//                     textAlign: "center",
//                   }}
//                   variant="p"
//                 >
//                   {item.heading}
//                 </Text>
//                 <Text
//                   style={{ color: "white", textAlign: "center" }}
//                   variant="h3"
//                 >
//                   {item.content}
//                 </Text>
//               </Stack>
//             </Box>
//           ))}
//         </Switcher>
//       </Center>
//     </Box>
//   )
// }

const OurVision: FC = props => {
  return (
    <Box>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)">
        <Stack space="var(--s1)">
          <Text variant="h2">Our Vision</Text>
          <Switcher threshold="var(--min-width)" space="var(--s2)" max={2}>
            <Box>
              <Text
                variant="h4"
                style={{
                  fontFamily: "var(--body-font-family)",
                  lineHeight: "150%",
                }}
                color="light"
              >
                We aim to lead the fintech revolution by reaching and lending
                money to the untapped market with credit needs in India. We
                envision ourselves as a go-to destination for the millions of
                unserved millennials and businesses suffering from cash-flow
                gaps and cater to their requirements.
              </Text>
            </Box>
            <Box>
              <Text
                variant="h4"
                style={{
                  fontFamily: "var(--body-font-family)",
                  lineHeight: "150%",
                }}
                color="light"
              >
                Our focus is to provide enhanced customer satisfaction and
                hassle-free user experience. We are continuously working on
                providing ease of loan to our customers and give them a stellar
                experience.
              </Text>
            </Box>
          </Switcher>
        </Stack>
      </Center>
    </Box>
  )
}
